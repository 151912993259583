import CountrySelect from '@/components/core/CountrySelect'
import { chakra, Input, useStyleConfig } from '@chakra-ui/react'
import { Countries } from 'constants/countries'
import React from 'react'
import PhoneInput from 'react-phone-number-input'

const ChakraPhoneInput = chakra(PhoneInput)

const InputComponent = React.forwardRef(function InputComp(props, ref) {
  const style = useStyleConfig('Input')
  return (
    <Input
      {...props}
      id="phone"
      ref={ref}
      borderLeftRadius={0}
      ml="-1px"
      css={{
        '&[aria-invalid]': {
          zIndex: 1
        }
      }}
      {...style}
    />
  )
})

const PhoneNumberInput = ({
  defaultCountry = Countries.US,
  placeholder,
  onChange,
  onBlur,
  value,
  disabled = false,
  ...rest
}) => {
  // Convert between '' <-> undefined
  // PhoneInput expects undefined instead of empty string
  // react-hook-form throws a warning with undefined so we need to convert it to empty string
  const internalValue = value ?? ''
  const internalOnChange = (value) => {
    onChange(value ?? '')
  }

  return (
    <ChakraPhoneInput
      display="flex"
      sx={{
        '.PhoneInputCountryIcon': {
          maxW: 24
        }
      }}
      placeholder={placeholder}
      defaultCountry={defaultCountry}
      disabled={disabled}
      countrySelectComponent={CountrySelect}
      inputComponent={InputComponent}
      onChange={internalOnChange}
      value={internalValue}
      onBlur={onBlur}
      name="phone"
      {...rest}
    />
  )
}

export default PhoneNumberInput
