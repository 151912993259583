import { Box, Container, Flex, Text, CloseButton, useBreakpointValue } from '@chakra-ui/react'
import { isBrowser } from '@/lib/utils'
import ApplePayPng from '@/public/images/apple_pay_acceptance_mark.png'
import GooglePayPng from '@/public/images/google_pay_acceptance_mark.png'
import Image from 'next/image'
import { useSignalContext } from '@/contexts/signal'
import React, { useEffect, useState } from 'react'
import { useEvent } from 'react-use'

const BannerPayment = ({paymentConfiguration}) => {
  const { featureFlags } = useSignalContext()
  const [showBanner, setShowBanner] = useState(true)
  const wpHeader = isBrowser ? document.getElementById('header') : null
  const mainDiv = isBrowser ? document.getElementById('main') : null
  const isMobile = useBreakpointValue({ base: true, md: false })

  const allowApplePay = paymentConfiguration.apple_pay_enabled && isBrowser && window.ApplePaySession
  const allowGooglePay = paymentConfiguration.google_pay_enabled
  const bannerActive = (allowApplePay || allowGooglePay) && featureFlags.payment_method_banner === 'test'

  let allowedMessage = ''
  if (allowApplePay) {
    allowedMessage = 'Apple Pay®'
  }
  if (allowGooglePay) {
    if (allowedMessage) {
      allowedMessage = `${allowedMessage} and `
    }
    allowedMessage = `${allowedMessage}Google Pay™`
  }
  const bannerMessage = `Book with confidence. Checkout seamlessly with ${allowedMessage}`

  const onScroll = React.useCallback(() => {
    if (wpHeader && bannerActive) {
      if (wpHeader.classList.contains('header-scrolled')) {
        wpHeader.style.paddingTop = "0px"
        mainDiv.style.marginTop = "0px"
      } else if (showBanner) {
        wpHeader.style.paddingTop = isMobile ? "80px" : "40px"
        mainDiv.style.marginTop = isMobile ? "80px" : "40px"
      }
    }
  }, [showBanner])

  useEvent('scroll', onScroll, undefined, { capture: true })

  useEffect(() => {
    if (wpHeader && bannerActive) {
      if (showBanner) {
        const paymentBanner = document.getElementById('paymentBanner')
        if (!paymentBanner) {
          setShowBanner(false)
          return
        }
        paymentBanner.style.position = "absolute"
        paymentBanner.style.top = "0"
        paymentBanner.style.zIndex = "502"
        wpHeader.style.paddingTop = isMobile ? "80px" : "40px"
        mainDiv.style.marginTop = isMobile ? "80px" : "40px"
      } else {
        wpHeader.style.paddingTop = "0px"
        mainDiv.style.marginTop = "0px"
      }
    }
  }, [showBanner])

  return <>
    {
      bannerActive && showBanner && (
        <Box
          id='paymentBanner'
          w="full"
          background="banner.payment"
          h={isMobile ? 20 : 10}
        >
          <Container maxW="container.2xl">
            <Flex
              h={isMobile ? 20 : 10}
              alignItems='center'
            >
              <Flex
                flexGrow={2}
                justifyContent='center'
                alignItems='center'
              >
                <Text fontSize={isMobile ? "sm" : "md"}> {bannerMessage} </Text>
                <Text fontSize="lg" ml={3}> | </Text>
                <Box ml={3} mt={2}>
                  { allowApplePay && <Image alt={'Apple Pay'} src={ApplePayPng} height={isMobile ? 52 : 26} width={isMobile ? 80 : 40} unoptimized /> }
                </Box>
                <Box ml={3} mt={2}>
                  { allowGooglePay && <Image alt={'Google Pay'} src={GooglePayPng} height={isMobile ? 52 : 26} width={isMobile ? 96 : 48} unoptimized /> }
                </Box>
              </Flex>
              <Box justifyContent='flex-end' >
                <CloseButton onClick={() => setShowBanner(false)} />
              </Box>
            </Flex>
          </Container>
        </Box>
      )
    }
  </>
}

export default BannerPayment
