import { useSignalContext } from '@/contexts/signal'
import circularTick from '@/icons/circular_tick.svg'
import RocketIcon from '@/icons/rocket.svg'
import { getApi } from '@/lib/client/api'
import { Box, Button, Flex, Icon, Text, useStyleConfig } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

const DemoCta = ({
  isQuoteSupported,
  ctaLine1,
  ctaLine2,
  direction = 'column',
  clientExpressedInterest,
  bg = 'beyond.200',
  ...rest
}) => {
  const boxStyling = useStyleConfig('Box', { variant: 'card' })
  const [expressedInterest, setExpressedInterest] = useState(false)
  const { token } = useSignalContext()
  const { t } = useTranslation('common')
  const isRow = direction === 'row'
  const api = getApi()

  useEffect(() => {
    setExpressedInterest(clientExpressedInterest)
  }, [clientExpressedInterest])

  const expressEngineInterest = async () => {
    await api.submitEngineInterest(token, isQuoteSupported)
    setExpressedInterest(true)
  }

  return (
    <Box
      sx={boxStyling}
      px={{ base: 4, md: isRow ? 10 : 6 }}
      py={{ base: 6, md: isRow ? 7 : 10 }}
      bg={bg}
      className={'border-card'}
      alignItems="center"
      display="flex"
      flexDir={{ base: 'column', md: direction }}
      mb="5"
      {...rest}
    >
      <Icon
        as={RocketIcon}
        boxSize="10"
        {...(isRow && { mr: { base: 0, md: 7 } })}
      />

      <Flex w="full" flexDir="column">
        <Text color="gray.800" fontWeight="bold" mt="3">
          {ctaLine1}
        </Text>
        <Text color="gray.600" mt={direction !== 'row' ? 4 : 2}>
          {ctaLine2}
        </Text>
      </Flex>

      <Flex
        w="full"
        justifyContent="center"
        alignItems={isRow ? { base: 'center', md: 'flex-end' } : 'center'}
        flexDir="column"
      >
        <Button
          colorScheme="demoCtaButton"
          className="btn-primary"
          mt="8"
          w={!isRow ? '100%' : 'auto'}
          size="lg"
          borderRadius="lg"
          onClick={expressEngineInterest}
          disabled={expressedInterest}
          _hover={{
            backgroundColor: expressedInterest ? 'beyond.700' : 'beyond.400',
            cursor: expressedInterest ? 'not-allowed' : 'pointer'
          }}
          _disabled={{
            backgroundColor: 'beyond.700'
          }}
          leftIcon={
            expressedInterest ? <Icon as={circularTick} boxSize="4" /> : ''
          }
          {...(isRow && { px: { base: 6, md: 12 }, mr: { base: 0, md: 5 } })}
        >
          <Text color="white">
            {expressedInterest
              ? t('demo-cta.submitted')
              : t('demo-cta.interested')}
          </Text>
        </Button>
        <Text
          color="beyond.700"
          mt="2"
          visibility={expressedInterest ? 'visible' : 'hidden'}
        >
          {t('demo-cta.thank-you')}
        </Text>
      </Flex>
    </Box>
  )
}

export default DemoCta
