import FormControlWithError from '@/components/core/form/FormControlWithError'
import PhoneNumberInput from 'components/inputs/PhoneNumberInput'
import { Controller, useFormContext } from 'react-hook-form'

const FormPhoneNumberInput = ({
  name,
  locale,
  placeholder,
  required = true,
  ...rest
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const defaultCountry = locale.split('-')[1]

  return (
    <FormControlWithError name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, onBlur, value } }) => (
          <PhoneNumberInput
            defaultCountry={defaultCountry}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...rest}
          />
        )}
      />
    </FormControlWithError>
  )
}

export default FormPhoneNumberInput
