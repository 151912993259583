import FormControlWithError from '@/components/core/form/FormControlWithError'
import { Input } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

const FormInput = ({
  additionalOptions,
  name,
  placeholder,
  validationRegExp,
  required = true,
  validate = true,
  onChange,
  disabled,
  value,
  ...rest
}) => {
  const { register, formState } = useFormContext()
  let options = {
    pattern: validationRegExp,
    required,
    validate,
    ...additionalOptions
  }
  return (
    <FormControlWithError name={name} errors={formState.errors}>
      <Input
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        _disabled={{
          cursor: 'not-allowed',
          borderColor: 'gray.200',
          opacity: 1,
          color: 'gray.600'
        }}
        {...register(name, options)}
        {...rest}
      />
    </FormControlWithError>
  )
}

export default FormInput
