import { Box, Flex, useTheme, useToken } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { getCountryCallingCode } from 'react-phone-number-input'
import ReactSelect, { components } from 'react-select'

const CountrySelect = ({
  value,
  onChange,
  options,
  disabled = false,
  iconComponent: Icon
}) => {
  const theme = useTheme()

  const [primaryColor, borderColor, bg2Color] = useToken('colors.wpMain', [
    'primary',
    'border',
    'bg2'
  ])
  const [gray200] = useToken('colors.gray', ['200'])

  const SingleValue = React.useCallback(function ({ data, ...props }) {
    return (
      <components.SingleValue {...props} css={{ width: '100%' }}>
        <Flex alignItems="center" justifyContent="center">
          <Box w={7} mr={2} color="gray.700">
            <Icon country={data.value} label={data.label} />
          </Box>
          {data.value && <>+{getCountryCallingCode(data.value)}</>}
        </Flex>
      </components.SingleValue>
    )
  }, [])

  const onChangeSelect = (item) => {
    onChange(item.value)
  }

  const selectedOption = useMemo(
    () => options.find((o) => o.value === value),
    [value, options]
  )

  const selectTheme = (t) => {
    return {
      ...t,
      borderRadius: 0,
      spacing: {
        ...t.spacing,
        controlHeight: 40
      }
    }
  }

  return (
    <ReactSelect
      instanceId={1}
      isDisabled={disabled}
      theme={selectTheme}
      styles={{
        container: (css) => ({
          ...css,
          zIndex: 1
        }),
        control: (css) => ({
          ...css,
          width: '130px',
          color: primaryColor,
          background: bg2Color,
          borderTopLeftRadius: theme.radii.md,
          borderBottomLeftRadius: theme.radii.md,
          borderColor: disabled ? gray200 : borderColor,
          img: {
            maxWidth: '30px'
          },
          '&:hover': {
            borderColor: borderColor
          }
        }),
        menu: (css) => ({
          ...css,
          width: '320px'
        }),
        input: (css) => ({
          ...css,
          color: primaryColor,
          input: {
            '&:focus': {
              boxShadow: 'none'
            }
          }
        })
      }}
      components={{
        SingleValue,
        IndicatorSeparator: null
      }}
      isSearchable={false}
      menuPosition="fixed"
      value={selectedOption}
      options={options}
      onChange={onChangeSelect}
    />
  )
}

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  iconComponent: PropTypes.elementType.isRequired
}
export default CountrySelect
